
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function hierarchicalContainerRT () {
    function repeatHierarchicalGroups1(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                return _createElement('div', { 'className': 'cm_vehicle-categories__topline-wrapper' }, _createElement('a', {
                    'href': !this.subvalues.length ? `${ this.href }` : `#nav_${ this.term }`,
                    'className': 'cm_vehicle-categories__topline-link',
                    'title': this.value
                }, _createElement('img', {
                    'alt': this.value,
                    'className': 'cm_vehicle-categories__topline-image cm_vehicle-categories_category-image',
                    'src': this.getImageOrDefault(this.imageUrl)
                }), _createElement('div', { 'className': 'cm_vehicle-categories__topline-title' }, this.value)));
            }, { count: undefined })];
    }
    function repeatHierarchicalGroups2(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                function repeatSubvalues1(subvalues, subvaluesIndex) {
                    return [subvalues(function () {
                            return _createElement('div', { 'className': 'cm_vehicle-categories_category-container' }, _createElement('a', {
                                'href': this.href,
                                'className': 'cm_vehicle-categories_link'
                            }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container' }, _createElement('img', {
                                'alt': this.value,
                                'className': 'cm_vehicle-categories_category-image',
                                'src': this.getImageOrDefault(this.imageUrl)
                            })), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container' }, _createElement('div', { 'className': 'cm_vehicle-categories_category-title' }, this.value), _createElement('svg', {
                                'className': 'icon chevron',
                                'title': 'icon chevron right'
                            }, _createElement('path', { 'd': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' })))));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_vehicle-categories_category-wrapper' }, !this.subvalues.length && !this.isMultiLevel ? [_createElement('div', {
                        'className': 'cm_vehicle-categories_category-container',
                        'key': '560'
                    }, _createElement('a', {
                        'href': this.href,
                        'className': 'cm_vehicle-categories_link'
                    }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container' }, _createElement('img', {
                        'alt': this.value,
                        'className': 'cm_vehicle-categories_category-image',
                        'src': this.getImageOrDefault(this.imageUrl)
                    })), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container' }, _createElement('div', { 'className': 'cm_vehicle-categories_category-title' }, this.value), _createElement('svg', {
                        'className': 'icon chevron',
                        'title': 'icon chevron right'
                    }, _createElement('path', { 'd': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' })))))] : null, this.subvalues.length ? [
                    _createElement('h1', {
                        'className': 'cm_vehicle-categories_category-wrapper-title',
                        'id': 'nav_' + this.term,
                        'key': '7641'
                    }, _createElement('a', {
                        'className': 'cm_vehicle-categories_category-wrapper-title-link cm_vehicle-categories_link',
                        'href': this.href
                    }, '\n        ', this.value, '\n      ')),
                    [this.vehicleWidget(function () {
                            return _createElement('div', { 'className': 'cm_vehicle-widget__vehicle-category-wrapper cm_vehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                                'className': 'cmTemplate_locked',
                                'key': '81'
                            }, this.vehicleString) : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })],
                    _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_vehicle-categories_category-repeater  cmRepeater_subvalues',
                            'key': '7645'
                        },
                        _map(this.subvalues, repeatSubvalues1.bind(this))
                    ])
                ] : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories' + (!this.isMultiLevel ? ' cm_vehicle-categories__flat ' : ' ') }, [this.vehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.firstSelectedTerm || '',
                            'disabled': this.disabled
                        }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n        ', window.themeSettings.locales.ymm[this.title.toLowerCase().replace(' ', '_')] || this.title, '\n      ') : null, this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '6511'
                            }, 'Popular Makes'),
                            _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '6514'
                            }, 'All Makes'),
                            _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                        ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null)));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__vehicle-categories  cm_vehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '87'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.themeSettings.locales.ymm.select || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_btn button button--small button--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    ', window.themeSettings.locales.ymm.go || 'GO', '\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_btn button button--small button--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, '\n    ', window.themeSettings.locales.ymm.clear || 'Clear', '\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '4414'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_btn button button--small button--primary',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      ', window.themeSettings.locales.ymm.change || 'Change', '\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_btn button button--small button--secondary',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      ', window.themeSettings.locales.ymm.clear || 'Clear', '\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], this.isMultiLevel ? _createElement.apply(this, [
        'div',
        {
            'className': 'cm_vehicle-categories_category-wrapper-repeater cm_vehicle-categories__topline  cmRepeater_hierarchicalGroups',
            'key': '6815'
        },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups1.bind(this))
    ]) : null, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories_category-wrapper-repeater cm_vehicle-categories_category-wrapper-repeater cmRepeater_hierarchicalGroups' },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups2.bind(this))
    ]));
}
        export const componentNames = ["cm:vehicleWidget","cm:vehicleWidget"]