//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-60:_7788,_1264,_7244,_4108,_3736,_7600,_2351,_4296;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-60')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-60', "_7788,_1264,_7244,_4108,_3736,_7600,_2351,_4296");
        }
      }catch (ex) {
        console.error(ex);
      }