
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, showVehicleSelector, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '41191'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function scopeShowVehicleSelector3(field, fieldIndex) {
                    var showVehicleSelector = this.vehicleSelector && field === 'Year';
                    return [
                        showVehicleSelector ? [[this.vehicleSelector(function () {
                                    return _createElement('div', { 'className': 'cm_vehicleSelector' }, [this.yearsSelect(function () {
                                            function repeatEntry1(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function repeatEntry2(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function repeatEntry3(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            return _createElement('div', { 'className': 'cm_yearsSelect' }, _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.firstSelectedTerm || '',
                                                'disabled': this.disabled
                                            }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                'value': '',
                                                'key': 'null-option'
                                            }, '\n        ', window.themeSettings.locales.ymm[this.title.toLowerCase().replace(' ', '_')] || this.title, '\n      ') : null, this.loading ? _createElement('option', {
                                                'key': 'loading-option',
                                                'disabled': true
                                            }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '6791'
                                                }, 'Popular Makes'),
                                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '6794'
                                                }, 'All Makes'),
                                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                            ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null))));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', { 'onClick': this.selectVehicle }, 'View All Parts'));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null,
                        !showVehicleSelector ? _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex, showVehicleSelector)) : null
                    ];
                }
                function repeatField4(field, fieldIndex) {
                    return _createElement('div', {
                        'className': 'fitment-cell fitment-field-' + field.toLowerCase(),
                        'key': this[field]
                    }, this[field] && this[field] !== 'Universal' ? scopeShowVehicleSelector3.apply(this, [
                        field,
                        fieldIndex
                    ]) : null);
                }
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'fitment-row' },
                    _map(this.fields, repeatField4.bind(this))
                ]);
            }, { count: undefined })];
    }
    function repeatItems2(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, showVehicleSelector, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '42961'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function scopeShowVehicleSelector3(field, fieldIndex) {
                    var showVehicleSelector = this.vehicleSelector && field === 'Year';
                    return _createElement.apply(this, [
                        'div',
                        { 'className': 'fitment-cell' },
                        showVehicleSelector ? [[this.vehicleSelector(function () {
                                    return _createElement('div', { 'className': 'cm_vehicleSelector' }, [this.yearsSelect(function () {
                                            function repeatEntry1(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function repeatEntry2(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function repeatEntry3(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            return _createElement('div', { 'className': 'cm_yearsSelect' }, _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty',
                                                'onChange': this.onChange,
                                                'value': this.firstSelectedTerm || '',
                                                'disabled': this.disabled
                                            }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                'value': '',
                                                'key': 'null-option'
                                            }, '\n        ', window.themeSettings.locales.ymm[this.title.toLowerCase().replace(' ', '_')] || this.title, '\n      ') : null, this.loading ? _createElement('option', {
                                                'key': 'loading-option',
                                                'disabled': true
                                            }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '6791'
                                                }, 'Popular Makes'),
                                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '6794'
                                                }, 'All Makes'),
                                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                            ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null))));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', { 'onClick': this.selectVehicle }, 'View All Parts'));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null,
                        !showVehicleSelector ? _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex, showVehicleSelector)) : null
                    ]);
                }
                function repeatField4(field, fieldIndex) {
                    return [this[field] && this[field] !== 'Universal' ? _createElement('div', {
                            'className': 'fitment-row',
                            'key': this[field]
                        }, _createElement('div', { 'className': 'fitment-cell' }, window.themeSettings?.locales?.ymm[field.toLowerCase()] || field), scopeShowVehicleSelector3.apply(this, [
                            field,
                            fieldIndex
                        ])) : null];
                }
                return itemsIndex !== 0 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'fitment-table__inner-container',
                        'key': '13'
                    },
                    _map(this.fields, repeatField4.bind(this))
                ]) : null;
            }, { count: undefined })];
    }
    return this.vehicleSpecific || this.manyFitments ? _createElement('div', {
        'className': 'cm_fitment-table card',
        'key': '0'
    }, _createElement('button', {
        'className': 'card__collapsible-button',
        'data-action': 'toggle-collapsible',
        'aria-expanded': 'false',
        'aria-controls': 'fitment-tab'
    }, _createElement('span', { 'className': 'card__title heading h3 fitment-table-title' }, 'Fitment'), _createElement('span', { 'className': 'plus-button plus-button--large' })), _createElement('div', {
        'id': 'fitment-tab',
        'className': 'card__collapsible'
    }, _createElement('div', { 'className': 'card__collapsible-content' }, _createElement('div', { 'className': 'fitment-table-container' }, this.template === 'horizontal' ? _createElement('div', {
        'className': 'cm_fitment-table__horizontal cmTemplate_horizontal',
        'key': '501'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])) : null, this.template === 'vertical' ? _createElement('div', {
        'className': 'cm_fitment-table__vertical cmTemplate_vertical',
        'key': '6086'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems2.bind(this))
    ])) : null)))) : null;
}
        export const componentNames = ["cm:yearsSelect","cm:vehicleSelector","cm:yearsSelect","cm:vehicleSelector"]