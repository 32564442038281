
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function homeVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.firstSelectedTerm || '',
                    'disabled': this.disabled
                }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                    'value': '',
                    'key': 'null-option'
                }, '\n        ', window.themeSettings.locales.ymm[this.title.toLowerCase().replace(' ', '_')] || this.title, '\n      ') : null, this.loading ? _createElement('option', {
                    'key': 'loading-option',
                    'disabled': true
                }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                    _createElement('option', {
                        'className': 'cm_option_title',
                        'disabled': true,
                        'key': '6511'
                    }, 'Popular Makes'),
                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                    _createElement('option', {
                        'className': 'cm_option_title',
                        'disabled': true,
                        'key': '6514'
                    }, 'All Makes'),
                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '59'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('h3', {}, window.themeSettings.locales.ymm.home_select || 'Select Your Vehicle')), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_btn button button--small button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ', window.themeSettings.locales.ymm.go || 'GO', '\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_btn button button--small button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, '\n    ', window.themeSettings.locales.ymm.clear || 'Clear', '\n  ')))) : null);
}
        export const componentNames = []